import axios from "axios";
import { getUserData } from "common/getUserData";
import React from "react";
import ReactDOM from "react-dom";
import SessionInactivePopup from "./common/SessionExpiredPopup";
const BASE_URL = process.env.REACT_APP_EDOC_API_URL || "";


function showSessionInactivePopup() {
  const div = document.createElement("div");
  document.body.appendChild(div);

  function handleClose() {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
    window.location.href = "/login";
  }

  ReactDOM.render(<SessionInactivePopup open={true} onClose={handleClose} />, div);
}
export default function getAxiosInstance(link, method, payload, headers) {
  let userData = getUserData();
  let requestObj = {
    method: method,
    url: `${BASE_URL}/${link}`,
    data: payload,
    headers: {
      Authorization: `Bearer ${userData?.accessToken}`,
    },
  };
  return axios(requestObj)
  .then((res) => res)
  .catch((err) => {
    console.log(err);
    if (link !== "auth/login" && err?.response?.status === 401) {
      showSessionInactivePopup();
    }
    return Promise.reject(err);
  });
}
